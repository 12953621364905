import './PokerGame.css';
import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";

// const fairShareUrl = 'http://localhost:8080';
//
const fairShareUrl = 'https://www.fair-share.uk';

function PokerGame() {
  useEffect(() => {
    getInitialCards()
  }, [])

  const getInitialCards = async () => {
    const response = await fetch(
        fairShareUrl + '/api/pokerGame/startGame',
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });

    const dataFromApi = await response.json();

    data.deckId = dataFromApi.deckId;
    data.cards.cardOne.image = dataFromApi.cards[0].image;
    data.cards.cardOne.code = dataFromApi.cards[0].code;

    data.cards.cardTwo.image = dataFromApi.cards[1].image;
    data.cards.cardTwo.code = dataFromApi.cards[1].code;

    data.cards.cardThree.image = dataFromApi.cards[2].image;
    data.cards.cardThree.code = dataFromApi.cards[2].code;

    data.cards.cardFour.image = dataFromApi.cards[3].image;
    data.cards.cardFour.code = dataFromApi.cards[3].code;

    data.cards.cardFive.image = dataFromApi.cards[4].image;
    data.cards.cardFive.code = dataFromApi.cards[4].code;
    setData(values => ({...values}))
  }

  const [data, setData] = useState({
    gameFinished: false,
    deckId: '',
    hand: '',
    cash: 10,
    cards: {
      cardOne: {
        code: '',
        image: '',
        selected: false
      },
      cardTwo: {
        code: '',
        image: '',
        selected: false
      },
      cardThree: {
        code: '',
        image: '',
        selected: false
      },
      cardFour: {
        code: '',
        image: '',
        selected: false
      },
      cardFive: {
        code: '',
        image: '',
        selected: false
      },
    }
  });

  const playAgain = async (e) => {
    data.gameFinished = false;

    data.cards.cardOne.selected = false;
    data.cards.cardTwo.selected = false;
    data.cards.cardThree.selected = false;
    data.cards.cardFour.selected = false;
    data.cards.cardFive.selected = false;

    document.getElementById("cardOne").style.opacity = "1";
    document.getElementById("cardTwo").style.opacity = "1";
    document.getElementById("cardThree").style.opacity = "1";
    document.getElementById("cardFour").style.opacity = "1";
    document.getElementById("cardFive").style.opacity = "1";

    document.getElementById("cardOne").style.removeProperty("border");
    document.getElementById("cardTwo").style.removeProperty("border");
    document.getElementById("cardThree").style.removeProperty("border");
    document.getElementById("cardFour").style.removeProperty("border");
    document.getElementById("cardFive").style.removeProperty("border");

    await getInitialCards()
  }

  const drawNewCards = async (e) => {
    data.cash = data.cash - 1;
    let swapCards = 0;
    if (!data.cards.cardOne.selected) {
      swapCards++
    }
    if (!data.cards.cardTwo.selected) {
      swapCards++
    }
    if (!data.cards.cardThree.selected) {
      swapCards++
    }
    if (!data.cards.cardFour.selected) {
      swapCards++
    }
    if (!data.cards.cardFive.selected) {
      swapCards++
    }
    const response = await fetch(
        fairShareUrl + '/api/pokerGame/' + data.deckId + '/getCards?noOfCards='
        + swapCards,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });

    const dataFromApi = await response.json();

    let i = 0;
    let c = [];
    if (!data.cards.cardOne.selected) {
      data.cards.cardOne.image = dataFromApi.cards[i].image
      data.cards.cardOne.code = dataFromApi.cards[i].code
      i++;
    }
    if (!data.cards.cardTwo.selected) {
      data.cards.cardTwo.image = dataFromApi.cards[i].image
      data.cards.cardTwo.code = dataFromApi.cards[i].code
      i++;
    }
    if (!data.cards.cardThree.selected) {
      data.cards.cardThree.image = dataFromApi.cards[i].image
      data.cards.cardThree.code = dataFromApi.cards[i].code
      i++;
    }
    if (!data.cards.cardFour.selected) {
      data.cards.cardFour.image = dataFromApi.cards[i].image
      data.cards.cardFour.code = dataFromApi.cards[i].code
      i++;
    }
    if (!data.cards.cardFive.selected) {
      data.cards.cardFive.image = dataFromApi.cards[i].image
      data.cards.cardFive.code = dataFromApi.cards[i].code
      i++;
    }
    c.push(data.cards.cardOne.code);
    c.push(data.cards.cardTwo.code);
    c.push(data.cards.cardThree.code);
    c.push(data.cards.cardFour.code);
    c.push(data.cards.cardFive.code);

    const response2 = await fetch(
        fairShareUrl + '/api/pokerGame/calculateHand?cards=' + c,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });

    const dataFromApi2 = await response2.json();
    let payout = dataFromApi2.payout;
    data.hand = dataFromApi2.name + " - you won " + payout;
    if (payout !== 0) {
      data.cash = data.cash + (payout * 1);
    }

    document.getElementById("cardOne").style.removeProperty("opacity");
    document.getElementById("cardTwo").style.removeProperty("opacity");
    document.getElementById("cardThree").style.removeProperty("opacity");
    document.getElementById("cardFour").style.removeProperty("opacity");
    document.getElementById("cardFive").style.removeProperty("opacity");

    let winningCards = dataFromApi2.winningCards;

    if (winningCards.includes(data.cards.cardOne.code)) {
      document.getElementById("cardOne").style.border = "5px solid yellow";
    } else {
      document.getElementById("cardOne").style.opacity = "0.1";
    }
    if (winningCards.includes(data.cards.cardTwo.code)) {
      document.getElementById("cardTwo").style.border = "5px solid yellow";
    } else {
      document.getElementById("cardTwo").style.opacity = "0.1";
    }
    if (winningCards.includes(data.cards.cardThree.code)) {
      document.getElementById("cardThree").style.border = "5px solid yellow";
    } else {
      document.getElementById("cardThree").style.opacity = "0.1";
    }
    if (winningCards.includes(data.cards.cardFour.code)) {
      document.getElementById("cardFour").style.border = "5px solid yellow";
    } else {
      document.getElementById("cardFour").style.opacity = "0.1";
    }
    if (winningCards.includes(data.cards.cardFive.code)) {
      document.getElementById("cardFive").style.border = "5px solid yellow";
    } else {
      document.getElementById("cardFive").style.opacity = "0.1";
    }

    data.gameFinished = true
    setData(values => ({...values}))
  }

  function getCardFromId(elementId) {
    if (elementId === 'cardOne') {
      return data.cards.cardOne;
    } else if (elementId === 'cardTwo') {
      return data.cards.cardTwo;
    } else if (elementId === 'cardThree') {
      return data.cards.cardThree;
    } else if (elementId === 'cardFour') {
      return data.cards.cardFour;
    } else {
      return data.cards.cardFive;
    }
  }

  const cardClicked = (e) => {
    let elementId = e.target.id;
    let card = getCardFromId(elementId);
    if (card.selected) {
      document.getElementById(elementId).style.removeProperty("opacity");
      card.selected = false;
    } else {
      document.getElementById(elementId).style.opacity = "0.1";
      card.selected = true;
    }
    setData(values => ({...values}))
  };

  return (data.cards.cardOne.image !== '' &&
      data.cards.cardTwo.image !== '' &&
      data.cards.cardThree.image !== '' &&
      data.cards.cardFour.image !== '' &&
      data.cards.cardFive.image !== '' &&
      <Container>
        <Row>
          <Col className="col-1"></Col>
          <Col className="col-2">
            <img onClick={cardClicked} id="cardOne"
                 className="playingCard fadeAnimation img-fluid"
                 src={data.cards.cardOne.image} alt="Card One"/>
          </Col>
          <Col className="col-2">
            <img onClick={cardClicked} id="cardTwo"
                 className="playingCard fadeAnimation img-fluid"
                 src={data.cards.cardTwo.image} alt="Card Two"/>
          </Col>
          <Col className="col-2">
            <img onClick={cardClicked} id="cardThree"
                 className="playingCard fadeAnimation img-fluid"
                 src={data.cards.cardThree.image} alt="Card Three"/>
          </Col>
          <Col className="col-2">
            <img onClick={cardClicked} id="cardFour"
                 className="playingCard fadeAnimation img-fluid"
                 src={data.cards.cardFour.image} alt="Card Four"/>
          </Col>
          <Col className="col-2">
            <img onClick={cardClicked} id="cardFive"
                 className="playingCard fadeAnimation img-fluid"
                 src={data.cards.cardFive.image} alt="Card Five"/>
          </Col>
          <Col className="col-1"></Col>
        </Row>
        {!data.gameFinished ? (
            <Button variant="primary" type="submit" onClick={drawNewCards}>
              DRAW
            </Button>) : (
            <Button variant="primary" type="submit" onClick={playAgain}>
              PLAY AGAIN
            </Button>
        )}
        <p>Cash: {data.cash}</p>
        {data.gameFinished ? (
            <p>{data.hand}</p>
        ) : (
            <p></p>
        )}
      </Container>
  )
}

export default PokerGame;