import './App.css';
import CalculateSplitForm from './CalculateSplitForm.js';
import PokerGame from './PokerGame.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Route, Routes} from 'react-router-dom';

function App() {

  return (
      <Routes>
        <Route path='/' element={<CalculateSplitForm/>}/>
        <Route path='/pokerGame' element={<PokerGame/>}/>
      </Routes>
  );
}

export default App;
