import './CalculateSplitForm.css';
import CurrencyInput, {formatValue} from 'react-currency-input-field';
import {useState} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Card, Col, Container, Row} from "react-bootstrap";
import CalculateSplitResult from "./CalculateSplitResult";
import CalculateMatchResult from "./CalculateMatchResult";
import CalculateSaveResult from "./CalculateSaveResult";

const personOneDefaultIncome = 2004;
const personTwoDefaultIncome = 3256;

// const fairShareUrl = 'http://localhost:8080';
const fairShareUrl = 'https://www.fair-share.uk';

function CalculateSplitForm() {

  const prefix = '£';

  const [inputs, setInputs] = useState({
    inputAmount: '',
    months: '24',
    personOneIncome: personOneDefaultIncome,
    personTwoIncome: personTwoDefaultIncome,
    splitResult: {
      personOneSplit: '',
      personTwoSplit: '',
    },
    saveResult: {
      personOneSplit: '',
      personTwoSplit: '',
    },
    matchResult: {
      person: '1',
      matchAmount: '',
      total: '',
    },
    showResult: false,
    inputType: 'split'
  });

  const handleChange = (name, value) => {
    setInputs(values => ({...values, [value]: name}))
  }

  const onSaveChange = (e) => {
    inputs.months = e.target.value;
    inputs.showResult = true;
    void handleSubmit(e)
  };

  async function getSplitAmounts() {
    const response = await fetch(
        fairShareUrl + '/api/getSplitAmounts',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "splitAmount": inputs.inputAmount,
            "incomes": {
              "personOneIncome": inputs.personOneIncome,
              "personTwoIncome": inputs.personTwoIncome
            }
          })
        });

    const data = await response.json();
    inputs.splitResult.personOneSplit = formatValue({
      prefix, value: String(data.personOneSplit), decimalScale: 2
    })
    inputs.splitResult.personTwoSplit = formatValue({
      prefix, value: String(data.personTwoSplit), decimalScale: 2
    })
  }

  async function getMatchAmount() {
    const response = await fetch(
        fairShareUrl + '/api/getMatchAmount',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "matchAmount": inputs.inputAmount,
            "person": inputs.matchResult.person,
            "incomes": {
              "personOneIncome": inputs.personOneIncome,
              "personTwoIncome": inputs.personTwoIncome
            }
          })
        });

    const data = await response.json();
    inputs.matchResult.matchAmount = formatValue({
      prefix, value: String(data.matchAmount), decimalScale: 2
    })
    inputs.matchResult.total = formatValue({
      prefix, value: String(data.total), decimalScale: 2
    })
  }

  async function getSaveAmount() {
    const response = await fetch(
        fairShareUrl + '/api/getSaveAmount',
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "saveAmount": inputs.inputAmount,
            "months": inputs.months,
            "incomes": {
              "personOneIncome": inputs.personOneIncome,
              "personTwoIncome": inputs.personTwoIncome
            }
          })
        });

    const data = await response.json();
    inputs.saveResult.personOneSplit = formatValue({
      prefix, value: String(data.personOneSplit), decimalScale: 2
    })
    inputs.saveResult.personTwoSplit = formatValue({
      prefix, value: String(data.personTwoSplit), decimalScale: 2
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (inputs.inputType === "split") {
      await getSplitAmounts();
    } else if (inputs.inputType === "match") {
      await getMatchAmount();
    } else {
      await getSaveAmount();
    }
    inputs.showResult = true
    setInputs(values => ({...values}))
  }

  function radioChange(e) {
    inputs.inputType = e.target.value;
    inputs.showResult = false;
    setInputs(values => ({...values}))
    if (!(inputs.inputType === "split") && inputs.inputAmount
        && inputs.personOneIncome && inputs.personTwoIncome
        && inputs.matchResult.person) {
      void handleSubmit(e)
    }
  }

  function personRadioChange(e) {
    inputs.matchResult.person = e.target.value;
    void handleSubmit(e)
  }

  return (
      <div>
        <Container className="vertical-center">
          <Row>
            <Col className="center">
              <Form onSubmit={handleSubmit}>
                <h1 className="center">FairShare 💸</h1>
                <Form.Group className="mb-4 center">
                  <CurrencyInput
                      name="personOneIncome"
                      placeholder="Person One's Income"
                      defaultValue={personOneDefaultIncome}
                      decimalsLimit={2}
                      prefix={prefix}
                      onValueChange={(value, name) => handleChange(value, name)}
                  />
                </Form.Group>
                <Form.Group className="mb-4 center">
                  <CurrencyInput
                      name="personTwoIncome"
                      placeholder="Person Two's Income"
                      defaultValue={personTwoDefaultIncome}
                      decimalsLimit={2}
                      prefix={prefix}
                      onValueChange={(value, name) => handleChange(value, name)}
                  />
                </Form.Group>
                <Form.Group className="mb-4 center">
                  <CurrencyInput
                      name="inputAmount"
                      placeholder={"Amount to " + inputs.inputType}
                      value={inputs.inputAmount}
                      decimalsLimit={2}
                      prefix={prefix}
                      onValueChange={(value, name, values) => handleChange(
                          value,
                          name, values)}
                  />
                </Form.Group>
                {inputs.inputType === "save" ? (
                    <Form.Group className="mb-4">
                      <Form.Label className="center">Number of Months to
                        Save: {inputs.months}</Form.Label>
                      <Form.Range
                          name="months"
                          min="1"
                          max="120"
                          value={inputs.months || ""}
                          onChange={onSaveChange}
                          disabled={!inputs.inputAmount}
                          className="center"/>
                    </Form.Group>) : null
                }
                <div key={`inline-radio`} className="mb-3 center">
                  <Form.Check
                      inline
                      label="Split Amount"
                      name="radioGroup"
                      type="radio"
                      value="split"
                      checked={inputs.inputType === "split"}
                      id="inline-radio-split"
                      onChange={radioChange}
                  />
                  <Form.Check
                      inline
                      label="Match Amount"
                      name="radioGroup"
                      type="radio"
                      value="match"
                      checked={inputs.inputType === "match"}
                      id="inline-radio-match"
                      onChange={radioChange}
                  />
                  <Form.Check
                      inline
                      label="Save Amount"
                      name="radioGroup"
                      type="radio"
                      value="save"
                      checked={inputs.inputType === "save"}
                      id="inline-radio-save"
                      onChange={radioChange}
                  />
                </div>

                {inputs.inputType === "match" ? (
                    <div key={`inline-radio-pers`} className="mb-3 center">
                      <Form.Check
                          inline
                          label="Person One"
                          name="personRadioGroup"
                          type="radio"
                          value="1"
                          checked={inputs.matchResult.person === "1"}
                          id="inline-radio-pers-one"
                          onChange={personRadioChange}
                          disabled={!inputs.inputAmount
                              || !inputs.personOneIncome
                              || !inputs.personTwoIncome}
                      />
                      <Form.Check
                          inline
                          label="Person Two"
                          name="personRadioGroup"
                          type="radio"
                          value="2"
                          checked={inputs.matchResult.person === "2"}
                          id="inline-radio-pers-two"
                          onChange={personRadioChange}
                          disabled={!inputs.inputAmount
                              || !inputs.personOneIncome
                              || !inputs.personTwoIncome}
                      />
                    </div>) : null
                }
                <div className="center">
                  <Button variant="primary" type="submit"
                          disabled={!inputs.inputAmount
                              || !inputs.personOneIncome
                              || !inputs.personTwoIncome}
                          hidden={!(inputs.inputType === "split")}>
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col className="center">
              <div id="result">
                {inputs.showResult ? (
                        <Card>
                          {inputs.inputType === "split" ? (
                              <CalculateSplitResult splits={{
                                personOneSplit: inputs.splitResult.personOneSplit,
                                personTwoSplit: inputs.splitResult.personTwoSplit
                              }}/>
                          ) : (
                              inputs.inputType === "match" ? (
                                  <CalculateMatchResult matchResult={{
                                    matchAmount: inputs.matchResult.matchAmount,
                                    total: inputs.matchResult.total
                                  }}/>
                              ) : (
                                  <CalculateSaveResult saveResult={{
                                    personOneSplit: inputs.saveResult.personOneSplit,
                                    personTwoSplit: inputs.saveResult.personTwoSplit
                                  }}/>
                              )
                          )
                          }
                        </Card>
                    )
                    : null
                }
              </div>
            </Col>
          </Row>
        </Container>

        <div>
          <h5>
            Other projects:
            <ul>
              <li>
                <a href="/pokerGame">Poker Game</a>
              </li>
            </ul>
          </h5>
        </div>
      </div>
  )
}

export default CalculateSplitForm;